<template>
    <div id="education">
        <component-education-navbar></component-education-navbar>
<!--        <img src="../../assets/images/education/education_bg.png" alt="" id="bg">-->
        <div class="content">
            <img src="../../assets/images/education/banner_bg.png" alt="" id="navbg">
            <div class="banner">
                <v-carousel
                        :show-arrows="false"
                        height="700"
                        cycle
                        >
                    <div class="banner-link" @click="bannerRedirect(item)" v-for="(item, i) in banners" :key="i">
                        <v-carousel-item
                                :src="staticPath + item.cnImageUrl"
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                        >
                        </v-carousel-item>
                    </div>
                    <!--<router-link :to="item.redirectUrl ? item.redirectUrl : '/education?n='+$route.query.n" v-for="(item, i) in banners" :key="i">
                        <v-carousel-item
                            :src="staticPath + item.cnImageUrl"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                        >
                        </v-carousel-item>
                    </router-link>-->
                </v-carousel>
            </div>
            <div>
                <img src="../../assets/images/education/intr.jpg" alt="">
                <img src="../../assets/images/education/classes.jpg" alt="">
            </div>
            <!--<div class="features">
                <div class="topic">
                    <img :src="require('@/assets/images/education/why-learn.png')" alt="">
                </div>
                <ul>
                    <li v-for="(item, index) in items" :key="index">
                        <img :src="item.url" alt="">
                        <h2>{{ item.name }}</h2>
                    </li>
                </ul>
            </div>
            <div class="advantage">
                <div class="topic">
                    <img :src="require('@/assets/images/education/why-choose.png')" alt="">
                </div>
                <div class="items" v-if="temp.jyzz && temp.jyzz.data.length > 0">
                    <div class="top">
                        <div class="img">
                            <img :src="require('@/assets/images/education/pic1.png')" alt="">
                        </div>
                        <div class="text">
                            <div class="item item1" v-for="(item, index) in temp.jyzz.data" :key="index" v-if="index < 3">
                                <div class="title" @click="toDetail(item)">
                                    <span>0{{ index + 1 }}.</span>{{ item.cnTitle }}
                                    <p>{{ item.cnContentSummary }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="text">
                            <div class="item item1" v-for="(item, index) in temp.jyzz.data" :key="index" v-if="index >= 3">
                                <div class="title" @click="toDetail(item)">
                                    <span>0{{ index + 1 }}.</span>{{ item.cnTitle }}
                                    <p>{{ item.cnContentSummary }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="img">
                            <img :src="require('@/assets/images/education/pic2.png')" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="course">
                <div class="topic">
                    <img :src="require('@/assets/images/education/course.png')" alt="">
                </div>
                <course-list-component :list="lessions" :maxNum="6"></course-list-component>

                <router-link :to="'/online/list/OTY%3D/courses'" class="btn-more">查看更多</router-link>
            </div>
            <div class="information">
                <div class="topic">
                    <img :src="require('@/assets/images/education/information.png')" alt="">
                </div>
                <div class="list" v-if="temp.jyhd && temp.jyhd.data.length > 0">
                    <div class="item" v-for="(item, index) in temp.jyhd.data" :key="index" v-show="index < 4">
                        <div class="pic" @click="toDetail(item)">
&lt;!&ndash;                            <span class="badge" :style="{'background-color': item.type === 1 ? 'rgba(217,60,69,0.8)' : 'rgba(27, 178, 255, 0.8)'}">{{ item.type === 1 ? '活动' : '新闻' }}</span>&ndash;&gt;
                            <img :src="staticPath + item.cnLogo" alt="">
                        </div>
                        <div class="text">
                            <h2 @click="toDetail(item)">{{ item.cnTitle }}</h2>
                            <span>{{ item.cnContentSummary }}</span>
                            <p>{{ item.updateTime | formatDate }}</p>
                        </div>
                        <i class="icon-more" @click="toDetail(item)"></i>
                    </div>
                </div>
                <router-link :to="'/online/list/OTI%3D/activities'" class="btn-more">查看更多</router-link>
            </div>-->
            <component-education-bottom :showBgColor="false"></component-education-bottom>
        </div>
    </div>
</template>
<script>
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import CourseListComponent from './component/courseListComponent.vue'
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "Education",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom,
            CourseListComponent
        },
        data() {
            return {
                staticPath,
                temp: '',
                banners: [],
                items: [
                    {
                        name: '培养动手能力',
                        url: require('@/assets/images/education/handson.png')
                    }, {
                        name: '构建知识体系',
                        url: require('@/assets/images/education/knowledge.png')
                    }, {
                        name: '锻炼观察力',
                        url: require('@/assets/images/education/observation.png')
                    }, {
                        name: '培养逻辑思维',
                        url: require('@/assets/images/education/logic.png')
                    }, {
                        name: '激发创造力',
                        url: require('@/assets/images/education/creativity.png')
                    }, {
                        name: '提升社交能力',
                        url: require('@/assets/images/education/social-skills.png')
                    }
                ],
                lessions: [],
                newsList: [],
                navId: '',

            };
        },
        computed: {
            "navIdNumToBase": function() {
                return encodeURIComponent(btoa(this.navId))
            }
        },
        methods: {
            getTemp() {
                this.$http.get(urls.getEducationTemp + this.navId).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.temp = res.data.data;
                        console.log(this.temp)
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getBannerList() { // 获取轮播
                this.$http.get(urls.getBannerList + this.navId).then(res => {
                    if (res.data.status === 200) {
                        this.banners = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            bannerRedirect(item) {
                if (item.redirectUrl) {
                    window.open(item.redirectUrl);
                }
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'ActivityDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            getCourseList() {
                this.$http.get(urls.getCourseList).then(res => {
                    if (res.data.status === 200) {
                        this.lessions = res.data.data.records;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getInformationList() {
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize,
                    navigationId: this.navId
                }
                this.$http.defaults.headers.get['Web-Site'] = 2;
                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    console.log(res);
                    if(res.data.status === 200) {
                        this.newsList = res.data.data.records;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.n) {
                this.navId = atob(decodeURIComponent(this.$route.query.n))
            }
            this.getTemp();
            this.getBannerList();
            this.getCourseList();
            // this.getInformationList();
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #education {
        min-width: 1380px;
        position: relative;
        #bg {
            position: absolute;
            left: 0;
            top: 0;
        }
        #navbg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
        }
        .content {
            width: 100%;
            /*padding-top: 20px;*/
            position: absolute;
            left: 0;
            top: 0;
            background: #F4FBFF;
            >div {
                /*width: 70%;*/
                margin: auto;
                .topic {
                    width: 372px;
                    margin: 2vw auto 3vw;
                }
            }
            .bottom-nav {
                width: 100% !important;
            }
            .banner {
                /*margin-top: 6vw;*/
                @{deep} .v-window {
                    /*height: 24vw !important;*/
                    .banner-link {
                        cursor: pointer;
                    }
                    .v-carousel__item {
                        height: 100% !important;
                    }
                    .v-image__image {
                        background-size: 100%;
                    }
                    .v-carousel__controls {
                        background-color: transparent;
                        padding-left: 15%;
                        v-index: 20;
                    }
                }
                @{deep} .v-carousel__controls {
                    justify-content: flex-start;
                    .v-btn--icon.v-size--small {
                        width: 1.125vw;
                        height: 1.125vw;
                    }
                    .mdi-circle::before {
                        content: '';
                    }
                    .theme--dark.v-btn.v-btn--icon {
                        background: #d7d7d7;
                        &.v-item--active {
                            background: #F7B527;
                        }
                    }
                }
            }
            .features {
                width: 60vw;
                text-align: center;
                margin-top: 13vw;
                ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    li {
                        width: 30%;
                        margin-bottom: 3vw;
                        img {
                            width: 60%;
                        }
                        h2 {
                            color: #395F74;
                            margin: 2vw 0;
                            font-size: 22px;
                        }
                    }
                }
            }
            .advantage {
                margin-top: 5vw;
                .items {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .top,
                    .bottom {
                        display: flex;
                        justify-content: space-between;
                        .img,
                        .text{
                            width: 50%;
                            box-sizing: border-box;
                            .item {
                                margin-top: 2vw;
                                &:nth-child(2) {
                                    margin-left: 5vw;
                                }
                                &:nth-child(3) {
                                    margin-left: 10vw;
                                }
                            }
                        }
                        .title {
                            font-size: 30px;
                            color: #2F3E59;
                            font-weight: bold;
                            cursor: pointer;
                            >span {
                                font-size: 60px;
                                color: #1BB2FF;
                            }
                            >p {
                                color: #70809B;
                                font-size: 20px;
                                padding-left: 4.2em;
                                line-height: 140%;
                                margin-top: .3vw;
                                display: -webkit-box;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                        .img {
                            padding: 5vw;
                        }
                    }
                }
            }

            .course,
            .information {
                margin-top: 6vw;
                .topic {
                    width: 200px;
                    margin: 2vw auto 3vw;
                }

                .btn-more {
                    display: block;
                    width: 11.45vw;
                    height: 3.64vw;
                    line-height: 3.64vw;
                    margin: 3vw auto;
                    font-size: 20px;
                    border-radius: 50px;
                    text-align: center;
                    background: linear-gradient(to right, #24CEFF, #1BB2FF);
                    box-shadow: 0 2px 15px 4px rgba(27, 178, 255, 0.5);
                    color: #fff;
                    box-sizing: border-box;
                }
            }
            .course{
                .list {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .item {
                        width: 30%;
                        background: #fff;
                        padding: 5px 5px 15px;
                        margin-bottom: 4vw;
                        border-radius: 8px;
                        box-shadow: 0 5px 15px 2px rgba(190, 238, 252, 0.7);
                        h2 {
                            margin: 10px 0;
                            font-size: 16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: break-all;
                        }
                        p {
                            height: 3em;
                            line-height: 150%;
                            color: #231E3F;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
            }
            .information {
                margin-top: 15vw;
                .btn-more {
                    margin-top: 6vw;
                }
                .list {
                    display: flex;
                    flex-wrap: wrap;
                    .item {
                        width: 23%;
                        background: #fff;
                        border-radius: 6px;
                        position: relative;
                        box-shadow: 0 2px 15px 4px rgba(27, 178, 255, 0.2);
                        margin: 0 2.666% 40px 0;
                        cursor: pointer;
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        .pic {
                            position: relative;
                            display: block;
                            .badge {
                                position: absolute;
                                left: 0.5vw;
                                top: 0.5vw;
                                color: #fff;
                                border-radius: 4vw;
                                display: inline-block;
                                padding: .2vw .6vw;
                                background: rgba(217, 60, 69, 0.8);
                            }
                            img {
                                width: 100%;
                                /*height: 200px;*/
                                border-radius: 6px 6px 0 0;
                            }
                        }
                        .text {
                            padding: 5px 20px 60px;
                            h2 {
                                font-size: 16px;
                                margin-bottom: 20px;
                                line-height: 150%;
                                color: #231E3F;
                                max-height: 3em;
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                            span {
                                font-size: 12px;
                                color: #aaa;
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                line-height: 150%;
                            }
                            p {
                                margin: .8vw 0;
                                color: #aaa;
                            }
                        }
                        .icon-more {
                            display: inline-block;
                            width: 1.458vw;
                            height: 0.9375vw;
                            background: url('../../assets/images/education/icon_more.png') no-repeat center center;
                            background-size: 100% 100%;
                            margin-top: 0.6vw;
                            cursor: pointer;
                            position: absolute;
                            bottom: 30px;
                            left: 20px;
                        }
                    }
                }
            }
            .bottom-container {
                width: 100%;
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    .fontFn2 (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem !important";
    }

     @media screen and (max-width: 750px) {
        #education{
            min-width: 320px;
            .content{
                &>div{
                    width: 100%;
                    .topic{
                        width: 100%;
                    }
                }
                .banner{
                    .v-window{
                        .fontFn2(125.36,height);
                        overflow:hidden;
                        @{deep} .v-carousel__controls{
                            padding-left: 24%;
                        }
                    }

                    @{deep} .v-carousel__controls{
                        .theme--dark.v-btn.v-btn--icon{
                            width: 8px;
                            height: 8px;
                        }
                    } 
                }
                .features{
                    width: 90%;
                    ul{
                        li{
                            h2{
                                .fontFn(14);
                            }
                        }
                    }
                }
                .advantage{
                    width: 90%;
                    .items{
                        .top{
                            flex-direction: column-reverse;
                            .text,
                            .img{
                                width: 100%;
                            }
                            .text{
                                .item{
                                    margin-bottom: 6%;
                                }
                                .item:nth-child(2),
                                .item:nth-child(3){
                                    margin-left: 0
                                }
                            }
                            .title{
                                .fontFn(20);
                                &>span{
                                    .fontFn(20);
                                }
                                &>p{
                                    .fontFn(14);
                                    padding-top: 2%;
                                    padding-left: 10%;
                                }
                            }
                        }
                        .bottom{
                            flex-direction: column;
                            .text,
                            .img{
                                width: 100%;
                            }
                            .text{
                                .item{
                                    margin-bottom: 6%;
                                }
                                .item:nth-child(2),
                                .item:nth-child(3){
                                    margin-left: 0
                                }
                            }
                            .title{
                                .fontFn(20);
                                &>span{
                                    .fontFn(20);
                                }
                                &>p{
                                    .fontFn(14);
                                    padding-top: 2%;
                                    padding-left: 10%;
                                }
                            }
                        }
                    }
                }
                .course,
                .information{
                    .btn-more{
                        .fontFn(14);
                        .fontFn(120,width);
                        .fontFn(40,height);
                        .fontFn(40,line-height);
                    }
                }
                .information{
                    .list{
                        width: 90%;
                        margin:0 auto;
                        .item{
                            width: 100%;
                            margin:0 auto 10%;
                            .text{
                                h2{
                                    .fontFn(16);
                                    margin-bottom: 2%;
                                }
                                span,
                                p{
                                    .fontFn(12);
                                }
                            }
                            .icon-more{
                                .fontFn(20,width);
                                .fontFn(16,height);
                            }
                        }
                    }
                }
            }
        }
     }
</style>
